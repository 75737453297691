import React from 'react';
import ReactDOM from 'react-dom';

const Main = () => {
  return (
    <main>
      <h1>Dennis Jin</h1>
      <p>
        Based in New York.
        Building software with a distinctive visual sensibility is the type of
        work I enjoy the most.
      </p>
      <p>
         Currently designing at Snapchat. Previously Zenly.
      </p>
      <p>
        Co-founder at{' '}
        <a href="https://dip.org" rel="nofollow noopener">
          Dip
        </a>{' '}
        – a design and technology studio with partners in Los Angeles, Adelaide,
        and Paris. We build delightful digital products for clients and also
        invest into our own ventures, such as{' '}
        <a href="https://rep.ly" rel="nofollow noopener">
          Rep.ly
        </a>
        .
      </p>
      <p>
        Previously at{' '}
        <a
          href="https://techcrunch.com/2020/06/18/mapillary-facebook/"
          rel="nofollow noopener"
        >
          Mapillary
        </a>{' '}
        (acq. by Facebook) building solutions for the future of mapping and
        mobility.
      </p>
      <p>
        <a href="mailto:mail@dennisjin.com">Email</a>
        {' '}
        <a href="https://twitter.com/seldom" rel="nofollow noopener">
          Twitter
        </a>
        {' '}
        <a href="https://instagram.com/dennis.jin" rel="nofollow noopener">
          Instagram
        </a>
      </p>
    </main>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
